import type { ProductVariant } from "@graphql/generated/components";
import {
    MAX_SHOE_SIZE,
    MIN_SHOE_SIZE,
    ONE_SIZE,
    SizeLocales,
} from "@lib/constants/filtersConstants";
import { Attributes } from "@lib/enums/ProductAttributes";
import { currentShoeConvention } from "@lib/utils/sizeLocaleUtility";
import { shoeConventionForSite } from "@config/site/site-config";
import { getProductAttributeValue } from "./attributesUtils";

const isWomenOrMen = (value: string): boolean =>
    value.toLocaleLowerCase() === "women" || value.toLocaleLowerCase() === "men";
/* this should perhaps be refactored and thrown out at some point, but
 it is currently in use in pdpShoeSizes that does stuff to figure out if product is a childrens or adults etc. based on
 the Size attribute which at any rate every product has (because EU size is in Size) and every non shoe has Size
 since all that code does not need to use the SIZE_UK etc. to work (as far as I can determine) and it may require a significant
 rethinking how we determine something is a sock, a children's shoe etc. if we throw it out.

 Am leaving it in for now.
 */
const minAndMaxSizes = {
    EU: { min: 35, max: 50, f: 43 },
    UK: { min: 2, max: 15 },
    US: { min: 3, max: 16 },
    CA: { min: 3, max: 16 },
    CN: { min: 215, max: 320 },
    KR: { min: 215, max: 325 },
};
export const isShoeSize = (item) => {
    return !isNaN(Number(item)) && Number(item) <= MAX_SHOE_SIZE && Number(item) >= MIN_SHOE_SIZE;
};

export const isShoeSizeByConvention = (n, locale) => {
    if (typeof n !== "string") {
        return;
    }
    let size = n;
    if (locale === SizeLocales.uk || SizeLocales.us) {
        size = n?.split("-")[0];
    }
    const nAsNumber = Number(size);
    if (isNaN(nAsNumber)) {
        return false;
    }
    const fixedLocale = locale ?? currentShoeConvention ?? SizeLocales.eu;
    const { min, max } = minAndMaxSizes[fixedLocale.toUpperCase()];
    return nAsNumber <= max && nAsNumber >= min;
};

export const isNotShoeSize = (item) => {
    return isNaN(Number(item)) || Number(item) > MAX_SHOE_SIZE || Number(item) < MIN_SHOE_SIZE;
};

export const hasOneSize = (item) => {
    if (typeof item === "string") {
        return item?.toLowerCase().includes(ONE_SIZE);
    } else {
        return false;
    }
};

export const getDescription = (categoryL1: string, categoryL3: string): string => {
    let result: string;
    if (categoryL1) result = isWomenOrMen(categoryL1) ? `${categoryL1}'s` : categoryL1;

    if (categoryL3) result = result ? `${result} ${categoryL3}` : categoryL3;

    return result;
};

export const getDiscount = ({ price, listPrice }) => {
    return ((1 - Number(price / listPrice)) * 100).toFixed(0);
};

export const getShoesAndOtherSizes = (initialFilters = []) => {
    const shoesSizes = initialFilters
        ?.filter((variant) => isShoeSize(variant.label))
        .sort((a, b) => parseInt(a.value) - parseInt(b.value));
    const otherSizes = initialFilters
        ?.filter((variant) => isNotShoeSize(variant.label))
        .sort((a, b) => parseInt(a.value) - parseInt(b.value));

    return { shoesSizes, otherSizes };
};

export const getVariantAttribute = (variant: ProductVariant, name: Attributes, locale?: string) => {
    return getProductAttributeValue(variant?.attributesRaw, name, locale);
};

export const getSizeAttributeByLocale = (variant: ProductVariant, sizeLocale: string) => {
    const sizeAtt = sizeLocale === "EU" ? "Size" : `Size_${sizeLocale}`;
    const result = getProductAttributeValue(variant?.attributesRaw, sizeAtt as Attributes, null);
    if (typeof result === "string") {
        return result;
    }
    return getProductAttributeValue(variant?.attributesRaw, Attributes.size, null);
};

export const getBestSizeAttributeAvailable = (variant: ProductVariant) => {
    const sizeConventions = shoeConventionForSite;
    if (!variant) {
        return "EU";
    }
    //change to while loop if we add more conventions but currently only 2
    if (Array.isArray(sizeConventions) && sizeConventions.length > 0) {
        const firstSizeLocale = sizeConventions[0];
        const firstAttemptedAtts = getSizeAttributeByLocale(variant, firstSizeLocale);
        if (firstAttemptedAtts.length > 0) {
            return firstAttemptedAtts;
        }
        const secondSizeLocale = sizeConventions[1];
        const secondAttemptedAtts = getSizeAttributeByLocale(variant, secondSizeLocale);
        if (secondAttemptedAtts.length > 0) {
            return secondAttemptedAtts;
        }
    }
    return getProductAttributeValue(variant?.attributesRaw, Attributes.size, null);
};

export const getAvailability = (variant: ProductVariant, supplyChannelId?: string) => {
    const isAvailableOnStore = variant?.availability.channels.results.find((x) => {
        return x.channel.id === supplyChannelId;
    });

    if (isAvailableOnStore && isAvailableOnStore.availability.isOnStock) {
        return isAvailableOnStore.availability;
    } else if (process.env.NEXT_PUBLIC_CHANNEL_ID) {
        const isAvailableInWarehouse = variant?.availability.channels.results.find((x) => {
            return x.channel.id === process.env.NEXT_PUBLIC_CHANNEL_ID;
        });

        if (isAvailableInWarehouse) {
            return isAvailableInWarehouse.availability;
        }
    }
    return null;
};

export const isClickAndCollect = (supplyChannelId: string) =>
    supplyChannelId !== process.env.NEXT_PUBLIC_CHANNEL_ID;
