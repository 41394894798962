import { hasWindow } from "./hasWindow";
import { shoeConventionForSite } from "@config/site/site-config";

export const defaultShoeConvention =
    Array.isArray(shoeConventionForSite) && shoeConventionForSite.length > 0
        ? shoeConventionForSite[0]
        : "EU";
const currentSizeLocale = hasWindow ? (window as any).sizeLocale : null;

export const currentShoeConvention = currentSizeLocale ?? defaultShoeConvention;

export const fullSizeFilter = `fullSizes.${currentShoeConvention.toLowerCase()}`;
