import type {
    CreateMyWishlistMutationVariables,
    DeleteMyWishlistMutationVariables,
    EccoGetMyActiveWishlistQueryVariables,
    GetMasterProductsQueryVariables,
    ProductQueryResult,
} from "@graphql/generated/components";
import {
    EccoGetMyActiveWishlistDocument,
    GetMasterProductsDocument,
    useCreateMyWishlistMutation,
    useDeleteMyWishlistMutation,
} from "@graphql/generated/components";
import { useRouter } from "next/router";
import { useClient } from "urql";
import { updateMutation, updateQuery, useStore } from "..";

export const useCreateMyWishlist = () => {
    const [_, execute] = useCreateMyWishlistMutation();
    const setData = useStore((state) => state.setWishlist);
    return updateMutation<CreateMyWishlistMutationVariables>(execute, setData);
};

export const useDeleteMyWishlist = () => {
    const [_, execute] = useDeleteMyWishlistMutation();
    const setData = useStore((state) => state.setWishlist);
    return updateMutation<DeleteMyWishlistMutationVariables>(execute, setData);
};

export const useGetMyActiveShoppingLists = () => {
    const client = useClient();
    const router = useRouter();
    const setData = useStore((state) => state.setWishlist);

    const selector = (data: any) => {
        return data.me.shoppingLists.results;
    };

    return updateQuery<EccoGetMyActiveWishlistQueryVariables>(
        client.query,
        setData,
        selector,
        EccoGetMyActiveWishlistDocument,
        {
            locale: router.locale,
        }
    );
};

export const useGetMyActiveShoppingList = () => {
    const client = useClient();
    const router = useRouter();
    const setData = useStore((state) => state.setWishlist);

    const selector = (data: any) => {
        return data.me.shoppingLists.results[0];
    };

    return updateQuery<EccoGetMyActiveWishlistQueryVariables>(
        client.query,
        setData,
        selector,
        EccoGetMyActiveWishlistDocument,
        {
            locale: router.locale,
        }
    );
};

export const useGetMasterDataForShoppingListLineItems = () => {
    const client = useClient();
    const setData = useStore((state) => state.setWishlistMasterProducts);

    const selector = (data: { products: ProductQueryResult }) => {
        const map = new Map();
        data.products?.results?.forEach((item) => {
            if (!map.has(item.id)) map.set(item.id, item.masterData.current);
        });

        return map;
    };

    const filter = {
        skus: "$skus",
        channelIds: process.env.NEXT_PUBLIC_CHANNEL_ID,
    };

    return updateQuery<GetMasterProductsQueryVariables>(
        client.query,
        setData,
        selector,
        GetMasterProductsDocument,
        filter
    );
};
