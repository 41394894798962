import { Tag } from "@ui/components/data-display/tag/Tag";
import { badgeStyles } from "@components/ui/product-badges/styles";
import type { Badge } from "@components/ui/product-badges/types";
import { ProductBadgeVariant } from "@components/ui/product-badges/types";
import { getBadge } from "@components/ui/product-badges/utils";
import type { IMicrocopy } from "@contentful-api/types/contentful";
import { Flex } from "@ui/components/layout/flex/Flex";
import { isEmpty } from "lodash-es";
import type { FC } from "react";
import { useMemo } from "react";
import { space } from "ui/src/design-tokens";

export interface ProductBadgesProps {
    badgesMicrocopy: { [key: string]: string };
    discount?: string;
    productBadge?: IMicrocopy;
    badges?: string[];
    isOnProductTile?: boolean;
}

export const badgesContainer = "badges-container";
export const discountBadge = "discount";

// TODO Add to market configuration
export const ON_SHELF_DATE_LIMIT = 30;
export const ProductBadges: FC<ProductBadgesProps> = ({
    badgesMicrocopy,
    discount,
    productBadge,
    badges,
    isOnProductTile = true,
}) => {
    const getMicrocopyForBadge = (badge: string) => {
        return Object.keys(badgesMicrocopy).find((key) =>
            key.toLowerCase().includes(badge.toLowerCase())
        );
    };

    const productBadges: Badge[] = useMemo(() => {
        const availableBadges: Badge[] = [];
        const badgesWithMicrocopies: { badge: string; microcopy: string }[] = [];
        badges?.forEach((b) => {
            const microcopy = getMicrocopyForBadge(b);

            if (microcopy) {
                badgesWithMicrocopies.push({
                    badge: b,
                    microcopy,
                });
            }
        });

        const saleBadgeIndex = badgesWithMicrocopies?.findIndex((b) => b.badge === "Sale");

        const newArrivalBadgeIndex = badgesWithMicrocopies?.findIndex(
            (b) => b.badge === "NewArrival"
        );

        if (discount) {
            availableBadges.push({
                name: discount,
                type: ProductBadgeVariant.GREEN,
                testId: discountBadge,
                order: 0,
            });
        } else if (saleBadgeIndex !== -1) {
            availableBadges.push(getBadge("product.badge.sale"));
        } else if (newArrivalBadgeIndex !== -1) {
            availableBadges.push(getBadge("product.badge.newArrivals"));
        }

        if (saleBadgeIndex !== -1) {
            badgesWithMicrocopies?.splice(saleBadgeIndex, 1);
        }

        if (newArrivalBadgeIndex !== -1) {
            badgesWithMicrocopies?.splice(newArrivalBadgeIndex, 1);
        }

        if (productBadge) {
            availableBadges.push({
                name: productBadge?.fields?.key,
                type: ProductBadgeVariant.OUTLINE,
                testId: productBadge?.fields?.value,
                order: 2,
            });
        }

        badgesWithMicrocopies?.forEach((badge) => {
            availableBadges.push(getBadge(badge.microcopy));
        });

        return availableBadges.sort(({ order: a }, { order: b }) => a - b).slice(0, 2);
    }, [discount, productBadge, badges]);

    if (isEmpty(productBadges) || isEmpty(badgesMicrocopy)) {
        return null;
    }

    return (
        <Flex
            alignItems={"center"}
            gap={space["0.2"]}
            //mt={4}
            flexWrap={"wrap"}
            data-testid={badgesContainer}
            sx={{
                ...(isOnProductTile ? { position: "absolute", top: 4, left: 4, right: 10 } : {}),
            }}
        >
            {productBadges?.map?.((badge, index) => {
                return (
                    <Tag
                        data-testid={`badge-${badge?.testId}`}
                        key={`badge-${badge.name}`}
                        sx={{ ...badgeStyles, paddingX: 2 }}
                        display={index > 0 ? ["none", "none", "none", "block"] : ["block"]}
                    >
                        {badge?.testId === discountBadge
                            ? badge.name
                            : badgesMicrocopy[badge.name] || badge.name}
                    </Tag>
                );
            })}
        </Flex>
    );
};
