import { space } from "@ui/design-tokens";

export const badgeStyles = {
    fontSize: ["mobileBodyTextSmall", "mobileBodyTextSmall", "desktopBodyTextSmall"],
    height: "auto",
    textAlign: "start",
    textTransform: "capitalize",
    fontWeight: "semibold",
    borderWidth: space["0.2"],
    borderStyle: "solid",
    bg: "gray.150",
    color: "black",
    lineHeight: "1.5",
};

export const greenBadgeStyles = {
    bg: "green.50",
    color: "black",
    borderColor: "green.50",
};

export const solidBadgeStyles = {
    bg: "black",
    color: "white",
    borderColor: "black",
};

export const outlineBadgeStyles = {
    bg: "transparent",
    color: "black",
    borderColor: "black",
};
