import { getSession, signIn } from "next-auth/react";
import { CommonCMS, Wishlist } from "@lib/constants/contentful";
import type { Session } from "next-auth";
import type {
    AddProductAndRemoveOldestMutationVariables,
    AddProductToWishlistMutationVariables,
    RemoveProductFromWishlistMutationVariables,
    ChangeQuantityInWishlistMutationVariables,
    ChangeProductSizeInWishlistMutationVariables,
    CreateMyWishlistMutationVariables,
    DeleteMyWishlistMutationVariables,
    ShoppingList,
    ShoppingListLineItem,
} from "@graphql/generated/components";
import type { ToastErrorMessage } from "../store-utility/storeUtility";
import type { MicrocopyGetOptions } from "@lib/contentful/microcopy/MicrocopyContext";

interface MyShoppingListResponse {
    error: Error | null;
    data: any | null;
}

type ErrorToasterFunction = (message: ToastErrorMessage) => number | string;

type MicrocopyGetFunction = (
    microcopySetKey: string,
    microcopyKey: string,
    options?: MicrocopyGetOptions
) => string;

const storeKey = process.env.NEXT_PUBLIC_STORE_KEY;

export function showErrorToaster(
    getMicrocopy: MicrocopyGetFunction,
    errorToaster: ErrorToasterFunction
): void {
    errorToaster({
        title: getMicrocopy(CommonCMS.global, CommonCMS.error.general.title),
        description: getMicrocopy(CommonCMS.global, CommonCMS.error.general.description),
    });
}

export async function signInAndGetSession(
    session: Session,
    getMicrocopy: MicrocopyGetFunction,
    errorToaster: ErrorToasterFunction
): Promise<void> {
    if (!session) {
        try {
            await signIn("anonymous", { redirect: false });
            await getSession();
        } catch {
            showErrorToaster(getMicrocopy, errorToaster);
        }
    }
}

export async function createMyShoppingList(
    locale: string,
    getMicrocopy: MicrocopyGetFunction,
    executeCreateAction: (
        variables: CreateMyWishlistMutationVariables
    ) => Promise<MyShoppingListResponse>,
    errorToaster: ErrorToasterFunction
): Promise<void> {
    const { error: getCreateMyShoppingListError } = await executeCreateAction({
        storeKey: storeKey,
        locale: locale,
        value: getMicrocopy(CommonCMS.page, Wishlist.title),
    });

    if (getCreateMyShoppingListError) {
        showErrorToaster(getMicrocopy, errorToaster);
        throw getCreateMyShoppingListError;
    }
}

export async function deleteMyShoppingList(
    locale: string,
    shoppingList: ShoppingList,
    getMicrocopy: MicrocopyGetFunction,
    executeDeleteAction: (
        variables: DeleteMyWishlistMutationVariables
    ) => Promise<MyShoppingListResponse>,
    errorToaster: ErrorToasterFunction
): Promise<void> {
    const { error: getDeleteMyShoppingListError } = await executeDeleteAction({
        shoppingListId: shoppingList.id,
        shoppingListVersion: shoppingList.version,
        storeKey: storeKey,
        locale: locale,
    });

    if (getDeleteMyShoppingListError) {
        showErrorToaster(getMicrocopy, errorToaster);
        throw getDeleteMyShoppingListError;
    }
}

export async function addToMyShoppingList(
    locale: string,
    currentShoppingList: ShoppingList,
    sku: string,
    executeAddAction: (
        variables: AddProductToWishlistMutationVariables
    ) => Promise<MyShoppingListResponse>,
    executeAddAndRemoveOldestAction: (
        variables: AddProductAndRemoveOldestMutationVariables
    ) => Promise<MyShoppingListResponse>,
    quantity?: number
): Promise<void> {
    const queryParams = {
        shoppingListId: currentShoppingList.id,
        shoppingListVersion: currentShoppingList.version,
        storeKey: storeKey,
        locale: locale,
    };

    const { error: getAddToMyShoppingListError } = await (currentShoppingList.lineItems.length >=
    250
        ? executeAddAndRemoveOldestAction({
              oldestItemId: currentShoppingList.lineItems[0].id,
              oldestItemQuantity: currentShoppingList.lineItems[0].quantity,
              sku: sku,
              ...queryParams,
          })
        : executeAddAction({
              ...queryParams,
              sku: sku,
              quantity: quantity ?? 1,
          }));

    if (getAddToMyShoppingListError) {
        throw getAddToMyShoppingListError;
    }
}

export async function removeFromMyShoppingList(
    locale: string,
    shoppingListLineItem: ShoppingListLineItem,
    currentShoppingList: ShoppingList,
    executeRemoveAction: (
        variables: RemoveProductFromWishlistMutationVariables
    ) => Promise<MyShoppingListResponse>
): Promise<void> {
    const { error: getRemoveFromMyShoppingListError } = await executeRemoveAction({
        shoppingListId: currentShoppingList.id,
        shoppingListVersion: currentShoppingList.version,
        storeKey: storeKey,
        locale: locale,
        lineItemId: shoppingListLineItem.id,
        quantity: shoppingListLineItem.quantity,
    });

    if (getRemoveFromMyShoppingListError) {
        throw getRemoveFromMyShoppingListError;
    }
}

export async function updateProductQtyInMyShoppingList(
    locale: string,
    shoppingListLineItem: ShoppingListLineItem,
    currentShoppingList: ShoppingList,
    executeUpdateQtyAction: (
        variables: ChangeQuantityInWishlistMutationVariables
    ) => Promise<MyShoppingListResponse>,
    newQty: number
): Promise<void> {
    const { error: getUpdateProductQtyError } = await executeUpdateQtyAction({
        lineItemId: shoppingListLineItem.id,
        quantity: newQty,
        shoppingListId: currentShoppingList.id,
        shoppingListVersion: currentShoppingList.version,
        storeKey: currentShoppingList.store.key,
        locale: locale,
    });

    if (getUpdateProductQtyError) {
        throw getUpdateProductQtyError;
    }
}

export async function updateProductSizeInMyShoppingList(
    locale: string,
    shoppingListLineItem: ShoppingListLineItem,
    currentShoppingList: ShoppingList,
    executeUpdateSizeAction: (
        variables: ChangeProductSizeInWishlistMutationVariables
    ) => Promise<MyShoppingListResponse>,
    sku: string
): Promise<void> {
    const { error: getUpdateProductQtyError } = await executeUpdateSizeAction({
        lineItemId: shoppingListLineItem.id,
        sku: sku,
        shoppingListId: currentShoppingList.id,
        shoppingListVersion: currentShoppingList.version,
        storeKey: currentShoppingList.store.key,
        locale: locale,
        addedAt: shoppingListLineItem.addedAt,
    });

    if (getUpdateProductQtyError) {
        throw getUpdateProductQtyError;
    }
}
