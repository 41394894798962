import {
    greenBadgeStyles,
    outlineBadgeStyles,
    solidBadgeStyles,
} from "@components/ui/product-badges/styles";
import type { IMicrocopy } from "@contentful-api/types/contentful";

export enum ProductBadgeVariant {
    GREEN = "green",
    SOLID = "solid",
    OUTLINE = "outline",
}

export const badgesStyles = {
    [ProductBadgeVariant.GREEN]: greenBadgeStyles,
    [ProductBadgeVariant.SOLID]: solidBadgeStyles,
    [ProductBadgeVariant.OUTLINE]: outlineBadgeStyles,
};

export interface Badge {
    name: string;
    type: ProductBadgeVariant;
    testId: string;
    order: number;
}

export const badgesMap: Badge[] = [
    {
        name: "product.badge.sale",
        type: ProductBadgeVariant.GREEN,
        testId: "sale",
        order: 1,
    },
    {
        name: "product.badge.newArrivals",
        type: ProductBadgeVariant.OUTLINE,
        testId: "newArrivals",
        order: 1,
    },
    {
        name: "product.badge.other",
        type: ProductBadgeVariant.OUTLINE,
        testId: "other",
        order: 2,
    },
    {
        name: "product.badge.onlineExclusive",
        type: ProductBadgeVariant.OUTLINE,
        testId: "onlineExclusive",
        order: 6,
    },
    {
        name: "product.badge.bestseller",
        type: ProductBadgeVariant.SOLID,
        testId: "bestseller",
        order: 3,
    },
    {
        name: "product.badge.upcycled",
        type: ProductBadgeVariant.SOLID,
        testId: "upcycled",
        order: 5,
    },
    {
        name: "product.badge.topRated",
        type: ProductBadgeVariant.SOLID,
        testId: "topRated",
        order: 4,
    },
];

export interface CustomBadgeWithProducts {
    badge: IMicrocopy;
    products: string[];
}
